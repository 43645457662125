















import { SearchBuilder } from "@/builder";
import Select from "@/components/custom/select/Select.vue";
import { debounceProcess } from "@/helpers/debounce";
import { useAsset } from "@/hooks";
import { Option } from "@/models/class/option.class";
import { RequestQueryParams } from "@/models/class/request-query-params.class";
import { DEFAULT_PAGE, ONE } from "@/models/constant/global.constant";
import { AssetStateEnum } from "@/models/enums/master-asset.enum";
import { RequestQueryParamsModel } from "@interface/http.interface";
import { AssetResponseDto } from "@interface/master-asset";
import { Component, Prop, Vue } from "vue-property-decorator";

@Component({
  components: {
    Select,
  },
})
export default class SelectUnit extends Vue {
  @Prop({ required: true, type: String, default: "" })
  value!: string;

  @Prop({ required: false, type: Boolean, default: false })
  disabled!: boolean;

  options: Option<AssetResponseDto>[] = [];
  loading = false;

  pagination = {
    page: DEFAULT_PAGE,
    search: "",
  };

  /**
   * @description
   * flag to determine if data is all loaded
   */
  allLoaded = false;

  mounted(): void {
    this.onSearch = debounceProcess(this.onSearch);
    this.fetchData(this.buildParams());
  }

  buildParams(): RequestQueryParamsModel {
    const params = new RequestQueryParams();
    const builder = new SearchBuilder();
    const defaultQuery = new SearchBuilder()
      .push(["status", AssetStateEnum.READY])
      .or()
      .push(["status", AssetStateEnum.NEED_TO_REPAIR])
      .or()
      .push(["status", AssetStateEnum.RENTED])
      .build();
    const { page, search } = this.pagination;
    const queries: string[] = [defaultQuery];
    if (search) {
      queries.push(search);
    }
    params.search = queries.join(builder.AND);
    params.page = page - ONE;

    return params;
  }

  async fetchData(params?: RequestQueryParamsModel): Promise<void> {
    const { findAllAsset, toOptionsNew } = useAsset();
    try {
      this.loading = true;
      const res = await findAllAsset(params);
      this.allLoaded = res.totalPages === res.currentPage + ONE;
      const exist = [...this.options];
      this.options = [...exist, ...toOptionsNew(res.data)];
    } finally {
      this.loading = false;
    }
  }

  onScrollEnd(): void {
    if (this.loading || this.allLoaded) return;
    this.pagination.page += ONE;
    this.fetchData(this.buildParams());
  }

  clearOptions(): void {
    this.options = [];
    this.pagination.page = ONE;
    this.allLoaded = false;
  }

  onSearch(val = ""): void {
    const { filterBy } = useAsset();
    const query = filterBy({ unitCode: val });
    this.pagination.search = query;

    this.clearOptions();
    this.fetchData(this.buildParams());
  }

  findOption(e?: string): Option<AssetResponseDto> | undefined {
    return this.options.find(item => item.value === e);
  }

  onChange(e?: string): void {
    this.$emit("input", e);
    this.$emit("change", e);
    this.$emit("update:meta", this.findOption(e));
  }
}
